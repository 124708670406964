import Nav from "react-bootstrap/Nav";
import React from "react";


function Footer(props) {
    return (
<div className="bottom-bar">

</div>);

}

export default Footer;
