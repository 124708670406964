import React, { Component } from "react";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MediaQuery from 'react-responsive'
import Nav from "react-bootstrap/Nav";


class Header extends Component {

    constructor(props) {
        super(props);
        this.addActiveClass= this.addActiveClass.bind(this);
        this.logoClickHandler = this.logoClickHandler.bind(this);
        this.state = {
            active: false
        };
    }

    logoClickHandler() {
        this.setState({ active: false});
        window.location = "/#/"
    }

    addActiveClass() {
        const currentState = this.state.active;
        this.setState({ active: !currentState });
    }

    render() {
        let pages = [{slug: "/", title: "Home", icon: "home"},
            {slug: "/howto/", title: "How To", icon: "laptop-code"},
            {slug: "/store/", title: "Store", icon: "home"},
            {slug: "/contact/", title: "Contact", icon: "envelope"}
        ];
        return (
            <div className={"top-bar"}>
                <div id={"main-logo"} onClick={this.logoClickHandler} className={"logo"}>
                    <img alt="Guess Logo" src={"/guesslogo.png"} className={"logo-fix"} />
                </div>
                {/*<MediaQuery maxDeviceWidth={750}>*/}
                {/*    <div id="circularMenu" className={this.state.active ? "circular-menu active" : "circular-menu"}>*/}

                {/*        <div className="floating-btn" onClick={this.addActiveClass}>*/}
                {/*            <FontAwesomeIcon icon="bars" size="2x" inverse/>*/}

                {/*        </div>*/}
                {/*        <menu className="items-wrapper">*/}
                {/*            {pages.map(page => (*/}
                {/*                <NavLink onClick={this.addActiveClass} key={page.title} className="menu-item fa"*/}
                {/*                         to={page.slug}><FontAwesomeIcon*/}
                {/*                    icon={page.icon} size="2x"/>*/}
                {/*                    <p className="menu-text">{page.title}</p> </NavLink>*/}
                {/*            ))}*/}
                {/*        </menu>*/}
                {/*    </div>*/}

                {/*</MediaQuery>*/}

                {/*<MediaQuery minDeviceWidth={751}>*/}
                <MediaQuery maxDeviceWidth={767}>
                <div id="sideMenu" className={this.state.active ? "side-menu active" : "side-menu"}>
                        <div className="floating-btn" onClick={this.addActiveClass}>
                            <FontAwesomeIcon icon="bars" size="2x" inverse/>
                        </div>
                        <ul className={this.state.active ? "side-nav active" : "side-nav"}>
                            {pages.map(page => (
                                <li className={"side-nav_item"}><NavLink className={"side-nav_link"} onClick={this.addActiveClass} key={page.title}
                                                                         to={page.slug}>{page.title}</NavLink></li>
                            ))}
                        </ul>
                    </div>
                </MediaQuery>
                <MediaQuery minDeviceWidth={768}>
                    <Nav className="justify-content-center" activeKey="/">

                        {pages.map(page => (
                            <Nav.Item>
                                <Nav.Link href={"/#"+page.slug} key={page.title}>{page.title}</Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </MediaQuery>

            </div>
        );
    }
}

export default Header;

