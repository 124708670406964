import React, { Component } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";

class ContactForm extends Component {
    constructor() {
        super();
        this.state = {
            name: "",
            email: "",
            message: "",
            status: "Submit"
        };
    }

    handleChange(event) {
        const field = event.target.id;
        if (field === "name") {
            this.setState({name: event.target.value});
        } else if (field === "email") {
            this.setState({email: event.target.value});
        } else if (field === "message") {
            this.setState({message: event.target.value});
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({status: "Sending"});
        axios({
            method: "POST",
            url: '/api/contact',
            data: this.state,
        }).then((response) => {
            if (response.data.status === "sent") {
                this.setState({name: "", email: "", message: "", status: "Submit"});
            } else if (response.data.status === "failed") {
                this.setState({status: "Failed"});
            }
        });
    }

    render() {
        let buttonText = this.state.status;
        return (
            <div>
            <h3>Contact Us</h3>
                <p>Enter your details below and we'll get back to you as soon as we can</p>
                <p>If your enquiry is about an existing order, please include the order ID</p>
            <form onSubmit={this.handleSubmit.bind(this)} method="POST">
                <div>
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        value={this.state.name}
                        onChange={this.handleChange.bind(this)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={this.state.email}
                        onChange={this.handleChange.bind(this)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="message">Message:</label>
                    <textarea
                        id="message"
                        value={this.state.message}
                        onChange={this.handleChange.bind(this)}
                        required
                    />
                </div>

                <Button variant="success" className="btn-primary  contact-btn" type="submit"
                        name="submitContact">{buttonText}</Button>
            </form>
            </div>
        );
    }
}

export default ContactForm;
