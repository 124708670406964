import React from "react";
import {Text, View, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    tile: {
        width: '2in',
        height: '3in',
        margin: 20,
        marginLeft: 10,
        backgroundSize: 'cover',
        position: 'relative',
        display: 'inline-flex'
    },
    image: {
        width: '2in',
        height: '3in',
        position: 'absolute',
        top: 20,
        left:20,
    },
    label: {
        color: 'white',
        backgroundColor: '#231f20',
        width: '100%',
        marginLeft:20,
        marginTop: 180,
        height: 35,
        textAlign: 'center',
        padding: 2,
    },

    text: {
        color: 'white',
        fontSize: 8,
        fontFamily: 'obelixproregular',
        display: 'flex',
        flex:1,
        flexDirection:'row',
        width: '100%',
    }
});


function LargeTile(props) {
    return (
        <View style={styles.tile}>
            <Image className="image-tile" style={styles.image}
                   src={props.image}/>
            <View style={styles.label}
                  className="image-title"><Text style={styles.text}>{props.name}</Text></View>
        </View>
    );
}

export default LargeTile;
