import { Page, View, StyleSheet } from '@react-pdf/renderer';
import React from "react";
import LargeTile from "./LargeTile";
import SmallTile from "./SmallTile";


const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
      width: '8.5in',
      padding:15,
      paddingTop:5
  },
    text: {
        fontFamily: 'obelixproregular',
    },

  section: {
      display: 'flex',
      flexDirection:'row',
      flexWrap: 'wrap',
      position: 'relative',
      flex: 1,
      width: '80%'
  }
});


function GuessWhoBoard(props) {
    console.log(props)
    const names = props.images
    let jsxComp = [];
    let innerJsx = [];

    for(let j =0; j<names.length; j++) {
        let nameFilter = names[j].name.toUpperCase();
        if(nameFilter.length > 14 && nameFilter.includes(" ")){
            nameFilter = nameFilter.substr(0, nameFilter.lastIndexOf(" ")) + "\n" + nameFilter.substr(nameFilter.lastIndexOf(" "));
        }
        let tileContent = props.tileType === "large" ? <LargeTile name={nameFilter} image={names[j].image} /> : <SmallTile name={nameFilter} image={names[j].image} size={props.size} />;
        innerJsx.push(tileContent);
    }

    jsxComp.push(<Page key={"page"} size="Letter" style={styles.page} >
        <View style={styles.section}>
            {innerJsx}
        </View>
    </Page>);

    return (
        jsxComp
    );
}

export default GuessWhoBoard;
