import React, {Component} from "react";

// import './css/pages/_store.scss'
import {PayPalButton} from "react-paypal-button-v2";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import {Modal, Button} from "react-bootstrap";
// const notify = () => toast("Wow so easy!");

class Store extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productsLoaded: false,
            products: [],
            show: false,
            selectedProduct: {}
        }
    }

    componentDidMount() {
        axios.get('/api/getProducts')
            .then((response) => {
                this.setState({products: response.data, productsLoaded: true, selectedProduct: response.data[0] });
            });
    }

    loadModal(index) {
        this.setState({selectedProduct: this.state.products[index], show:!this.state.show});
    }
    toggleModal() {
        this.setState({show:!this.state.show});
    }

    render() {
        if (this.state.productsLoaded) {
            return (

                <div>
                    <h2 className="page-title">Store</h2>
                    <Modal show={this.state.show} onHide={this.toggleModal.bind(this)}>
                        <Modal.Header closeButton>
                            <Modal.Title>{this.state.selectedProduct.name}</Modal.Title>
                        </Modal.Header>
                        {/*<Modal.Body><p>Featuring {this.state.selectedProduct.name.length} characters from {this.state.selectedProduct.shows} </p></Modal.Body>*/}
                        <Modal.Body><p>Featuring {this.state.selectedProduct.count} Characters from {this.state.selectedProduct.from}</p>
                            <ul>
                                {this.state.selectedProduct.characters.map((character, index) => (
                                    <li>{character}</li>
                                    ))}
                            </ul>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.toggleModal.bind(this)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>


                    <p>You can buy pre-built packs that you can just download and print.</p>
                    <p>Each pack comes with four files (a set of small, medium, and large face cards as well as a set of player
                        cards)</p>
                    {/*<h3 className="section-title">Available packs</h3>*/}
                    <div className="grid">
                        {this.state.products.filter(product => product.active).map((product, index) => (
                            <div key={index} className="product" onClick={()=>this.loadModal(index)}>
                                <p>{product.name}</p>
                                <img className="product-image"
                                     src="https://a-size.com/wp-content/uploads/letter-size-paper-2.jpg"/>
                                <p>${product.price}</p>
                                <PayPalButton
                                    amount={product.price}
                                    shippingPreference="NO_SHIPPING"
                                    onSuccess={(details, payData) => {
                                        console.log(details);
                                        console.log(payData.orderID)
                                        axios.post('/api/order', {
                                            orderId: payData.orderID,
                                            name: details.payer.name.given_name,
                                            product: product.name
                                        })
                                            .then(function (response) {
                                                console.log(response);
                                            })
                                    }}
                                    options={{currency: "CAD"}}
                                />
                            </div>
                        ))}
                    </div>

                    <h3 className="section-title">Coming soon</h3>
                    {/*<div className="productList">*/}
                    {/*    {this.state.products.filter(product => !product.active).map((product, index) => (*/}
                    {/*        <div key={index} className="product coming-soon">*/}
                    {/*            <p>{product.name}</p>*/}
                    {/*            <img className="product-image"*/}
                    {/*                 src="https://a-size.com/wp-content/uploads/letter-size-paper-2.jpg"/>*/}
                    {/*        </div>*/}
                    {/*    ))}*/}
                    {/*</div>*/}

                    <div className="grid">
                        {this.state.products.filter(product => !product.active).map((product, index) => (
                            <div key={index} className="product coming-soon">
                                <p>{product.name}</p>
                                <img className="product-image"
                                     src="https://a-size.com/wp-content/uploads/letter-size-paper-2.jpg"/>

                                <p className={"tbd-marker"}>Price TBD</p>

                            </div>
                        ))}
                    </div>

                </div>
            );
        }else{
            return (
                <div><p>Loading Products...</p></div>
            )
        }
    }
}

export default Store;
