import React from 'react';
import ReactDOM from 'react-dom';
import './css/main.css';
import reportWebVitals from './reportWebVitals';
import {Route, HashRouter} from "react-router-dom";
import HowTo from "./howto";
import Store from "./store";
import UploadFiles from "./uploadFiles";
import Header from "./header";
import ContactForm from "./contact";
import Footer from "./footer";
import { library } from '@fortawesome/fontawesome-svg-core'
import {faInfo, faEnvelope, faLaptopCode, faHome, faBars, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
library.add(faInfo, faEnvelope, faLaptopCode, faHome, faBars, faArrowCircleRight)
// import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
    <HashRouter>

            <Header />
            <div className="content">
                <Route exact path="/" component={UploadFiles}/>
                <Route path="/howto" component={HowTo}/>
                <Route path="/store" component={Store}/>
                <Route path="/contact" component={ContactForm}/>

            </div>
            <Footer />
        </HashRouter>
            ,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
